.custom-container[data-tier="1"] {
  background: #FAFAFA;
}

.custom-container[data-tier="1"] > .container-body {
    padding: 1rem 0rem 3.75rem 1.25rem !important;
    /* overflow-y: hidden; */
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: inherit;
}

.custom-container > .container-body.collapsable > .custom-container-top-bar {
    cursor: pointer;
}

@media (min-width: 992px) {
    .custom-container[data-tier="1"] > .container-body {
        position: absolute;
    }
}

.custom-container[data-tier="1"] > .container-body > .custom-container-top-bar {
    font-size: 1.1rem;
    /* font-weight: bold; */
    color: rgb(49, 49, 49);
    /* color: var(--color-primary-dark-border); */
}
.custom-container-sub-title {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
    white-space: pre-line;
}
@media (max-width: 992px) {
    .custom-container-sub-title {
        margin-top: 4px;
        font-size: 1rem;
    }
}

.custom-container[data-tier="2"] > .container-body > .custom-container-sub-title,
.custom-container[data-tier="2"] > .container-body > .custom-container-top-bar {
    padding-left: 4px;
}

.custom-container > .container-body .top-btn,
.custom-container > .container-body .collapse-btn {
    float: right;
    padding: 0 12px;
    position: relative;
    top: 2px;
}

@media (min-width: 576px) {
    .custom-container[data-collapses="sx"] > .container-body .collapse-btn { display: none; }
    .custom-container[data-collapses="sx"][data-collapsed="true"] > .container-body > .container-children { display: block !important; }
}
@media (max-width: 576px) { .custom-container[data-collapses="sx"][data-collapsed="true"] > .container-body > .container-children { display: none; } }
@media (min-width: 768px) {
    .custom-container[data-collapses="md"] > .container-body .collapse-btn { display: none; }
    .custom-container[data-collapses="md"][data-collapsed="true"] > .container-body > .container-children { display: block !important; }
}
@media (max-width: 768px) { .custom-container[data-collapses="md"][data-collapsed="true"] > .container-body > .container-children { display: none; } }
@media (min-width: 992px) {
    .custom-container[data-collapses="lg"] > .container-body .collapse-btn { display: none; }
    .custom-container[data-collapses="lg"][data-collapsed="true"] > .container-body > .container-children { display: block !important; }
}
@media (max-width: 992px) { .custom-container[data-collapses="lg"][data-collapsed="true"] > .container-body > .container-children { display: none; } }
.custom-container[data-collapses="xl"][data-collapsed="true"] > .container-body > .container-children { display: none; }

.custom-container[data-tier="1"] > .container-body > .container-children {
    height: 100%;
    margin-top: 16px;
}
.custom-container[data-tier="1"] ::-webkit-scrollbar {
    width: 1.25rem;
    height: 18px;
}
@media (min-width: 992px) {
    .custom-container[data-tier="1"] > .container-body > .container-children {
        overflow-y: scroll;
    }
    .custom-container[data-tier="1"] > .container-body .top-btn,
    .custom-container[data-tier="1"] > .container-body > .custom-container-top-bar > .collapse-btn {
        margin-right: 12px;
    }
}

.custom-container[data-tier="1"] > .container-body > .custom-container-top-bar > .close-btn {
    margin-right: 12px;
}
.custom-container[data-tier="1"] > .container-body > .custom-container-top-bar > .close-btn svg {
    font-size: 1.4rem;
    position: relative;
    top: 5px;
}


.custom-container[data-tier="1"] ::-webkit-scrollbar-thumb {
    height: 6px;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.custom-container[data-tier="1"] ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.custom-container[data-tier="1"] ::-webkit-scrollbar-corner {
    background-color: transparent;
}

.custom-container[data-tier="2"][data-no-title="false"] > .container-body > .container-children { margin-top: 16px; }
.custom-container[data-tier="3"][data-no-title="false"] > .container-body > .container-children { margin-top: 12px; }

@media (max-width: 992px) {
    .custom-container[data-tier="1"] > .container-body > .container-children {
        margin-top: 12px;
    }
    .custom-container[data-tier="1"] > .container-body {
        padding: 0.8rem 0.8rem 0.6rem 0.8rem !important;
    }
    .custom-container[data-tier="1"][data-collapsed="true"] > .container-body {
        padding: 0.8rem 0.8rem 0.6rem 0.8rem !important;
    }
    .custom-container[data-tier="1"] > .container-body > .custom-container-top-bar {
        font-size: 1.3em;
    }
    .custom-container[data-tier="1"] > .container-body > .custom-container-top-bar > .close-btn svg {
        font-size: 1.3rem;
        position: relative;
        top: 2px;
    }
    .custom-container > .container-body .top-btn,
    .custom-container > .container-body .collapse-btn {
        float: right;
        padding: 0 8px;
        height: 26px;
    }
    .custom-container[data-tier="2"][data-no-title="false"] > .container-body > .container-children { margin-top: 16px; }
    .custom-container[data-tier="3"][data-no-title="false"] > .container-body > .container-children { margin-top: 14px; }
}

.custom-container[data-tier="2"][data-small="false"] {
    background: rgb(255, 255, 253);
    padding: 14px 18px;
}

.custom-container[data-tier="2"][data-small="false"] > .container-body > .custom-container-top-bar {
    color: var(--color-primary);
    font-size: 1.2rem;
}

.custom-container[data-tier="3"][data-small="false"]  > .container-body > .custom-container-top-bar {
    font-size: 1.1rem;
    font-weight: 300;
}
.custom-container[data-tier="3"][data-small="true"]  > .container-body > .custom-container-top-bar {
    font-size: 1.25rem;
}

/* @media (max-width: 992px) {
    .custom-container[data-tier="2"][data-small="false"]  {
        padding: 6px 16px;
    }
    .custom-container[data-tier="2"][data-small="false"] > .container-body > .custom-container-top-bar {
        font-size: 1.4rem;
    }
    .custom-container[data-tier="3"][data-small="false"] > .container-body > .container-children { padding: 0px 6px }
    .custom-container[data-tier="3"][data-small="false"]  > .container-body > .custom-container-top-bar {
        font-size: 1.2rem;
    }
    .custom-container[data-tier="3"][data-small="true"]  > .container-body > .custom-container-top-bar {
        font-size: 1.15rem;
    }
    .custom-container[data-tier="2"] > .container-body {
        padding: 0.8rem 0rem;
    }
    .custom-container[data-tier="3"] > .container-body {
        padding: 0.8rem;
    }
} */



.custom-container[data-tier="2"][data-small="true"] { background: rgb(255, 255, 253) }
.custom-container[data-tier="2"][data-small="true"] > .container-body { padding: 12px }
.custom-container[data-tier="2"][data-small="true"] > .container-body > .container-children { padding: 0px 12px 12px 12px }



.custom-container[data-tier="3"][data-small="false"] { background: rgb(255, 253, 244) }
.custom-container[data-tier="3"][data-small="false"] > .container-body { padding: 12px }
.custom-container[data-tier="3"][data-small="false"] > .container-body > .container-children { padding: 0px 12px }

.custom-container[data-tier="3"][data-small="true"] { background: rgb(255, 253, 244) }
.custom-container[data-tier="3"][data-small="true"] > .container-body { padding: 12px }
.custom-container[data-tier="3"][data-small="true"] > .container-body > .container-children { padding: 0px 12px }

.custom-container[data-tier="2"] > .container-body > .custom-container-top-bar,
.custom-container[data-tier="3"] > .container-body > .custom-container-top-bar {
    color: rgb(49, 49, 49);
}

.custom-container:not([data-tier="1"]) > .container-body > .custom-container-top-bar .borderless-button {
    float: right;
}

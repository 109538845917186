

.user-info-dialog .icon {
    font-size: 1.3rem !important
}

.users-container .td-admin {
    width: 100px;
}
.users-container .td-icon,
.user-info-dialog .td-icon {
    width: 56px;
}

.custom-container .users-container .td-info {
    width: 48px;
}

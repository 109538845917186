.segmented-controls > .btn > input[type="radio"] {
    display: none;
}

.segmented-controls > .btn:not(:disabled):not(.disabled).active,
.segmented-controls > .btn:not(:disabled):not(.disabled):active,
.segmented-controls > .show >.btn.dropdown-toggle {
    color: rgb(255, 255, 255) !important;
    background-color: var(--color-primary) !important; /*rgb(52, 65, 141) !important;*/
    border-color: var(--color-primary) !important; /*rgb(47, 60, 134) !important;*/
}

.segmented-controls > .btn:hover,
.segmented-controls > .btn:focus {
    /* background-color: rgba(var(--color-primary-vals), 0.1) !important; */
    border-color: var(--color-primary) !important; /*rgb(58, 75, 170);*/
}

.segmented-controls > .btn {
    background-color: rgb(255, 255, 255) !important;
    border-color: var(--color-primary) !important; /*rgb(58, 75, 170) !important;*/
    color: var(--color-primary) !important; /*rgb(46, 58, 124) !important;*/
    outline: none !important;
    box-shadow: none !important;
}

.main-page {
  height: 100%;
  width: 100%;
}


.main-page .left-container,
.main-page .right-container {
  display: inline-block;
  height: 100%;
  padding-bottom: 84px;
}

.main-page .left-container {
  padding-left: 12px;
  padding-right: 6px;
  padding-top: 12px;
  width: 25%;
  display: inline-block;
  max-width: 400px;
  margin-left: auto;
}
.main-page .right-container {
  padding-right: 12px;
  padding-left: 6px;
  padding-top: 12px;
  width: 75%;
  max-width: 1200px;
  margin-right: auto;
}
@media (max-width: 992px){
  .main-page .left-container {
    padding: 8px 8px 0 8px;
  }
  .main-page .right-container {
    padding: 8px;
    margin-bottom: 8px;
  }
  .main-page .left-container,
  .main-page .right-container {
    display: inline-block;
    height: auto;
    max-width: 100%;
    width: 100%;
    padding-bottom: 0px;
  }
}
@media (max-width: 576px) {
  .main-page .left-container {
    padding: 4px 4px 0 4px;
    max-width: 100%;
    width: 100%;
  }
  .main-page .right-container {
    padding: 4px;
    margin-bottom: 0px;
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .main-page {
    overflow-y: hidden;
  }
  .main-page .left-container > .card,
  .main-page .right-container > .card {
    height: 100%;
  }
  .main-page .center-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
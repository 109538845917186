.month-analytics-container {
    margin-bottom: 18px;
}

.month-analytics-container p {
    font-size: 0.95rem;
}
@media (max-width: 992px) {
    .month-analytics-container {
        margin-bottom: 16px;
    }
    .month-analytics-container p {
        font-size: 0.9rem;
    }
}

.month-analytics-container .info-btn {
    margin-left: 8px;
    position: relative;
    top: 4px;
}
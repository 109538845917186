#signUpForm {
    vertical-align: middle;
    background:  #fbfbfb;
    padding: 56px 80px;

    border-radius: .25rem;
    border: 1px solid #dbdbdb;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
}

@media (max-width:576px) {
    #signUpForm {
        width: 100%;
        border-radius: 0px;
        box-shadow: 0 0 0;
        /* background: 0; */
        padding: 56px 40px;
    }
}

#signUpForm input[name="phone"]::-webkit-outer-spin-button,
#signUpForm input[name="phone"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

#signUpForm > #signUpBtn {
    margin-top: 24px;
}

#signUpForm > #signUpTitle {
    margin-bottom: 32px;
}

#signUpForm > #errorMessage {
    margin-bottom: 0;
    color: red;
}
.btn-outline-primary {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-primary {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
    color: #FFFF !important;
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-primary:hover,
.btn-primary:focus {
    background-color: var(--color-primary-light) !important;
    border-color: var(--color-primary-light) !important;
}

.btn[data-highlight-on-focus="false"] {
    outline: none !important;
    box-shadow: none !important;
}

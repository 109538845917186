.borderless-button {
    transition: 0.175s;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}
.borderless-button[data-bold="true"] {
    font-weight: bold;
}
.borderless-button > .title {
    margin-bottom: 0;
}
.borderless-button[data-type="default"] { color: rgb(90, 90, 90); }
.borderless-button[data-type="primary"] { color: var(--color-primary) }
.borderless-button[data-type="danger"][data-disabled="false"] { color: rgb(150, 0, 0); }
.borderless-button[data-type="danger"][data-disabled="true"] { color: rgb(179, 87, 87); }
.borderless-button[data-type="white"] { color: rgb(255, 255, 255); }
.borderless-button[data-type="white"][data-disabled="true"] { color: rgb(200, 200, 200); }

.borderless-button[data-disabled="false"]:hover[data-type="default"][data-shadow-type="none"] {
    color: rgb(50, 50, 50);
}
.borderless-button[data-disabled="false"]:hover[data-type="primary"][data-shadow-type="none"] {
    color: var(--color-dark-gray)
}
.borderless-button[data-disabled="false"]:hover[data-type="danger"][data-shadow-type="none"] {
    color: rgb(100, 0, 0);
}
.borderless-button[data-disabled="false"]:hover[data-type="white"][data-shadow-type="none"] {
    color: rgb(180, 180, 180);
}

.borderless-button:hover[data-type="default"][data-shadow-type="material"] {
    box-shadow: 0px 0px 0px 8px rgba(148, 148, 148, 0.5);
    background: rgba(148, 148, 148, 0.5);
    color: rgb(80, 80, 80);
}
.borderless-button:hover[data-type="primary"][data-shadow-type="material"] {
    box-shadow: 0px 0px 0px 8px rgba(var(--color-primary-light-vals), 0.1);
    background: rgba(var(--color-primary-light-vals), 0.1);
    color: var(--color-primary-light);
}
.borderless-button[data-disabled="false"]:hover[data-type="danger"][data-shadow-type="material"] {
    box-shadow: 0px 0px 0px 8px rgba(207, 33, 33, 0.1);
    background: rgba(207, 33, 33, 0.1);
    color: rgb(207, 33, 33);
}
.borderless-button:hover[data-type="white"][data-shadow-type="material"] {
    box-shadow: 0px 0px 0px 8px rgba(232, 232, 232, 0.5);
    background: rgba(232, 232, 232, 0.5);
    color: rgb(180, 180, 180);
}
.borderless-button[data-disabled="false"]:hover[data-type="default"][data-shadow-type="text_shadow"],
.borderless-button[data-disabled="false"]:hover[data-type="white"][data-shadow-type="text_shadow"] {
    text-shadow: 0 0 3px rgb(160, 160, 160);
}
.borderless-button[data-disabled="false"]:hover[data-type="primary"][data-shadow-type="text_shadow"] {
    text-shadow: 0 0 3px var(--color-primary-light);
}
.borderless-button[data-disabled="false"]:hover[data-type="danger"][data-shadow-type="text_shadow"] {
    text-shadow: 0 0 3px rgb(207, 33, 33);
}

.borderless-button[data-tier="1"] > .title {
    font-size: 1.5rem;
}
.borderless-button[data-tier="1"] > .icon {
    font-size: 1.5rem;
}
.borderless-button[data-tier="2"] > .title {
    font-size: 1.17rem;
}
.borderless-button[data-tier="2"] > .icon {
    font-size: 1.2rem;
}
.borderless-button[data-tier="3"] > .title {
    font-size: 1.0rem;
}
.borderless-button[data-tier="3"] > .icon {
    font-size: 1rem;
}
.borderless-button[data-tier="4"] > .title {
    font-size: 0.85rem;
}
.borderless-button[data-tier="4"] > .icon {
    font-size: 0.7rem;
}

/* .borderless-button:hover[data-tier="3"][data-type="default"],
.borderless-button:hover[data-tier="4"][data-type="default"] {
    box-shadow: 0px 0px 0px 8px rgba(160, 160, 160, 0.1);
}
.borderless-button:hover[data-tier="3"][data-type="primary"],
.borderless-button:hover[data-tier="4"][data-type="primary"] {
    box-shadow: 0px 0px 0px 8px rgba(var(--color-primary-light-vals), 0.1);
}
.borderless-button:hover[data-tier="3"][data-type="danger"],
.borderless-button:hover[data-tier="4"][data-type="danger"] {
    box-shadow: 0px 0px 0px 8px rgba(207, 33, 33, 0.1);
} */

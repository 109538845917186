@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;300;400;700&display=swap');

:root {
  --color-primary-light-vals: 255, 162, 0; /* Secondary */
  --color-primary-vals: 255, 125, 0;
  --color-dark-gray-vals: 30, 43, 48;

  --color-primary-light: rgb(var(--color-primary-light-vals));
  --color-primary: rgb(var(--color-primary-vals));

  --color-primary-dark-border: rgb(var(--color-dark-gray-vals));
  --color-dark-gray: rgb(var(--color-dark-gray-vals));
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: rgb(70, 70, 70);
}

body > #root {
  height: 100vh;
}
body > #root > div {
  height: 100%;
}

textarea {
  resize: none !important;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
}

/* h2 {
  font-weight: 300;
  font-size: 2.4rem;
  padding-bottom: 2rem;
} */

p {
  margin-bottom: 6px;
}

.light-font {
  font-weight: 300;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.inline-block {
  display: inline-block;
}

.center-this {
    display: block;
    margin: auto;
}

.vh-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-align-center {
    text-align: center;
}

h5 {
  color: rgb(60, 60, 60)
}

.react-date-picker__button {
  border: 0;
}

.card {
  border: 1px solid rgba(0,0,0,.08);
}

.space-right {
  margin-right: 24px;
}
.space-right-s {
  margin-right: 12px;
}

.icon-small {
  width: 12px;
  height: 12px;
}

.icon-medium {
  width: 20px;
  height: 20px;
}

svg {
  fill: currentColor;
}

.map-page {
	/* position: fixed; */
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

.map-navbar {
	position: relative;
	background-color: var(--color-primary-dark-border);
	height: 57px;
	width: 100%;
}

.map-navbar>div {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 12px 24px;
}

.map-navbar .navbar-icon {
	color: #fff;
}

.map-navbar .back-btn:hover {
	opacity: 0.6;
	cursor: pointer;
}

.map-container {
	width: 100%;
	height: calc(100vh - 57px); /* Full height - navbar*/
}



.map-inner-view {
	background-color: #fafafa;
	border-radius: 2px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}





.map-filters {
	position: absolute;
	left: 10px;
	bottom: 24px;
	width: 240px;
}

.map-filters .top-view {
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.map-filters .content-view {
	padding-top: 4px;
	border-top: solid 1px #e1e1e1;
	max-height: calc(100vh - 173px);
	overflow-y: auto;
}

.marker-row {
	display: flex;
	align-items: center;
	padding: 0 0 0 12px;
}

.new-marker-row {
	display: flex;
	align-items: center;
	padding: 6px 6px;
	min-width: 160px;
	border-radius: 2;
}

.new-marker-row p {
	margin: 0;
	height: 17px;
}

.new-marker-row:hover {
	cursor: pointer;
	font-weight: 600;
	background-color: #e1e1e1;
}

.marker-row .marker, .new-marker-row .marker {
	height: 24px;
	width: 24px;
	border-radius: 50%;
	margin-right: 12px;
	border: solid 1px #e1e1e1;
}



.bg-fade-view-12345 {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3)
}

.removed-points-modal {
	background-color: #fafafa;
	position: absolute;
	width: 400px;
	max-width: calc(100vw - 12px);
	top: calc(30vh);
	left: 50%;
	transform: translate(-50%, 0%);
	padding: 16px;
}

.removed-points-modal > h4 {
	margin-bottom: 14px;
}

.removed-points-modal > div > div > p {
	margin-bottom: 2px;
	font-size: 0.925rem;
}

.removed-points-modal .react-datepicker-wrapper,
.removed-points-modal .react-datepicker-wrapper > div,
.removed-points-modal .react-datepicker-wrapper > div > input,
.removed-points-modal > div > button {
	width: 100%;
}

@media (min-width: 768px) {
	.removed-points-date-pickers { display: flex; }
	.removed-points-modal > div > p { display: none; }
	.removed-points-date-pickers > div:nth-of-type(1) { margin-right: 12px; }
}
@media (max-width: 768px) {
	.removed-points-date-pickers > div:nth-of-type(1) { margin-bottom: 8px; }
}

.new-point-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	overflow-y: auto;
	max-height: 100vh;
}

.new-point-modal {
	display: block;
	width: 500px;
	max-width: 100%;
	background-color: #fafafa;
	border-radius: 4px;
	padding: 16px;
	padding-bottom: 8px;
	margin: auto;
}


/* AUTOCOMPLETE / SEARCH BAR */
.map-search-bar {
	position: absolute;
	top: 11px; /*64px;*/
	height: 36px;
	padding-left: 16px;
	left: calc(50vw - 200px);
	width: 400px;
	max-width: 100%;
	border-radius: 2px;
	outline: none;
	border: none;
}

.map-search-cancel-button,
.map-search-button,
.map-fullscreen-button {
	display: none;
	position: absolute;
	top: 16px;
}

.map-search-cancel-button { left: 24px; }
.map-search-button { right: 24px; }
.map-fullscreen-button { right: 76px; }

@media (max-width: 768px) {
	.map-search-cancel-button,
	.map-search-button,
	.map-fullscreen-button {
		display: block;
	}

	.map-search-bar { display: none; }
	.map-search-bar.map-search-bar--searching {
		display: block;
		width: calc(100vw - 70px - 24px);
		left: 70px;
	}

	.map-csv-button {
		position: absolute;
		right: 60px;
	}
}

@media (min-width: 768px) {
	.map-fullscreen-button.map-is-fullscreen {
		display: block;
		right: 24px;
	}

	.map-csv-button {
		position: absolute;
		right: 20px;
	}
}

.layer-controls {
	position: absolute;
	right: 0;
	bottom: 142px;
	box-shadow: 0 0 0 2px rgba(0,0,0,.1);
	margin: 0 10px 10px 0;
	border-radius: 4px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #fff;
}

.layer-control-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	cursor: pointer;
}

.layer-control-row label {
	margin: 0;
	font-size: 16px;
	font-family: Lato, sans-serif;
	pointer-events: none;
}

.material-switch:not([data-no-padding]) {
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.material-switch[disabled] {
    pointer-events: none;
}
.material-switch:not([disabled]) {
    cursor: pointer;
}

.material-switch > div {
    margin-top: 3px;
}

.material-switch > div > .switch > input[type="checkbox"] {
    display: none;
}

.material-switch > div > .switch {
    margin-top: 2px;
}

.material-switch > div > .switch > label {
    cursor: pointer;
    pointer-events: none;
    height: 0px;
    position: relative;
    width: 40px;
    background-color: var(--color-primary);
}
.material-switch[disabled] > div > .switch > label {
    background-color: rgb(170, 170, 170);
}


.material-switch > div > .switch > label::before {
    background: rgb(0, 0, 0);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > div > .switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch[disabled] > div > .switch > label::after {
    background: rgb(221, 221, 221);
}
.material-switch > div > .switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > div > .switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}
.dialog-generator.modal.fade > .modal-dialog {
    margin: 0 auto;
    /* padding-bottom: 32px; */
}

/* .dialog-generator.modal.fade > .modal-dialog {
    transform: translate(0, 47vh) translate(0, -50%) !important;
}

.dialog-generator.modal.show > .modal-dialog {
    /* -webkit-transform: translate(0, calc(50vh - 50%)) !important;
    -ms-transform: translate(0, 50vh) translate(0, -50%) !important;
    -o-transform: translate(0, calc(50vh - 50%)) !important; * /
    transform: translate(0, 50vh) translate(0, -50%) !important;
} */

.dialog-generator.modal > .modal-dialog {
    transform: translate(0, 50vh) translate(0, -50%) !important;
}







.btn-outline-primary {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-primary {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
    color: #FFFF !important;
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-primary:hover,
.btn-primary:focus {
    background-color: var(--color-dark-gray) !important;
    border-color: var(--color-dark-gray) !important;
}

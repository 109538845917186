.signInForm {
    vertical-align: middle;
    background:  #fbfbfb;
    padding: 96px 80px;
    border: 1px solid;
    border-radius: .25rem;
    border-color: #dbdbdb;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.15); */
    user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 700px;
}

@media (max-width:576px) {
    .signInForm {
        width: 100%;
        border-radius: 0px;
        box-shadow: 0 0 0;
        padding: 56px 40px;
    }
}

.signInForm #signUpBtn,
.signInForm #forgotPasswordBtn {
    margin-left: 8px;
}

/* Use if  */
/* .signInForm .signInTitle {
    color: var(--color-dark-gray);
    margin-bottom: 32px;
} */

.project-container .text {
    font-weight: 300;
    font-size: 1.1rem;
    margin-bottom: 4px;
}
.project-container .files-card {
    margin-top: 32px;
}

@media (max-width: 992px) {
    .project-container .text {
        font-size: 1rem;
    }
    .project-container .files-card {
        margin-top: 16px;
    }
}
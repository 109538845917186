.custom-dialog.modal.fade {
    pointer-events: none;
}

.custom-dialog.modal.fade > .modal-dialog {
    margin: 0 auto;
    /* padding-bottom: 32px; */
}
.custom-dialog {
    padding-right: 0 !important;
}

.custom-dialog.modal.fade > .modal-dialog > .modal-content > .modal-body {
    overflow-y: auto;
    max-height: 70vh;
    padding-left: 32px;
    padding-right: 32px;
}

.custom-dialog.modal.fade > .modal-dialog {
    transform: translate(0, 47vh) translate(0, -50%);
}

.custom-dialog.modal.show > .modal-dialog {
    /* -webkit-transform: translate(0, calc(50vh - 50%)) !important;
    -ms-transform: translate(0, 50vh) translate(0, -50%) !important;
    -o-transform: translate(0, calc(50vh - 50%)) !important; */
    transform: translate(0, 50vh) translate(0, -50%);
}


.custom-dialog.modal > .modal-dialog .modal-footer > button {
    min-width: 15%
}

/* 
@media (max-width: 992px) {
    .modal-dialog {
        width: 100%;
        height: 100%;
    }
} */

@media (max-width:576px) {
    .custom-dialog .modal-header,
    .custom-dialog .modal-footer {
        padding: 12px;
    }
    .custom-dialog .modal-body {
        padding: 16px !important;
    }
}
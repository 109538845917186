/*REGULAR*/
.loading-spinner {
  top: 10%;
  margin: auto;
  width: 48px;
  padding-bottom: 32px;
  position: relative;
}
.loading-spinner > .spinner {
  border: 6px solid #0000000e;
  border-radius: 50%;
  border-top: 6px solid var(--color-primary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  width: 42px;
  height: 42px !important;
}


/*SMALL*/
.loading-spinner-small {
	top: 10%;
	margin: auto;
	width: 34px;
	position: relative;
	padding-bottom: 8px;
 }
 .loading-spinner-small > .spinner-small {
	border: 4px solid #0000000e;
	border-radius: 50%;
	border-top: 4px solid #989898;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
	width: 26px;
	height: 26px !important;
 }


/*AMIMATIONS*/
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

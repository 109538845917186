.selectable-card {
    user-select: none;
    transition: 0.12s;
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    color: rgb(49, 49, 49);
    min-width: 156px;
}
.selectable-card > .file-input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.selectable-card > .react-date-picker {
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
}
.selectable-card > .react-date-picker > .react-date-picker__button {
    width: 100%;
}
.selectable-card > .react-date-picker > .react-date-picker__button > .react-date-picker__clear-button,
.selectable-card > .react-date-picker > .react-date-picker__button > .react-date-picker__button__input {
    display: none;
}
.selectable-card > .react-date-picker > .react-date-picker__button > .react-date-picker__calendar-button {
    width: 100%;
    opacity: 0;
}
/* .selectable-card .react-calendar__tile--active {
    background: var(--color-primary);
} */
.selectable-card .react-calendar__tile:not([disabled]):hover {
    background: var(--color-primary) !important;
    color: #FFFFFF;
}


.selectable-card[data-disabled="false"][data-selectable="true"][data-selected="false"] {
    cursor: pointer;
}

.selectable-card > .center-content {
    text-align: left;
    flex: 3 0px;
}
.selectable-card > .center-content > p {
    margin-bottom: 0;
}
.selectable-card > .left-content {
    flex: 0; order: 1;
}
.selectable-card > .center-content {
    order: 2;
}
.selectable-card > .right-content {
    margin-left: 12px;
    margin-right: 12px;
}
.selectable-card > .right-content {
    flex: 0;
    order: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectable-card > .left-content {
    margin-left: 6px;
    margin-right: 6px;
}

.selectable-card[data-tier="1"] > .left-content > .icon {
    color: var(--color-dark-gray);
    transition: 0.175s;
    cursor: pointer;

}
.selectable-card[data-tier="2"] > .left-content > .icon {
    vertical-align: middle;
    padding-bottom: 2px;
    color: rgb(95, 95, 95);
    transition: 0.175s;
    cursor: pointer;
}

.selectable-card[data-tier="1"] {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0.4rem;

}
.selectable-card[data-tier="2"] {
    padding: 0.4rem;
}

.selectable-card[data-small="false"] {
    border-width: 1px;
}
.selectable-card[data-small="true"] {
    border-width: 0.6px;
}


.selectable-card[data-tier="1"][data-disabled="false"] {
    background: rgba(var(--color-primary-light-vals), 0.1);
    border-color: var(--color-primary-light);
    color: var(--color-dark-gray);
}
.selectable-card[data-tier="1"][data-disabled="true"] {
    background: rgba(179, 179, 179, 0.25);
    border-color: rgb(202, 202, 202);
    color: rgb(109, 109, 109);
}
.selectable-card[data-tier="1"][data-highlighted][data-disabled="false"] {
    background: rgba(var(--color-primary-light-vals), 0.5);
    border-color: var(--color-primary-light);
}
.selectable-card[data-tier="1"][data-highlighted][data-disabled="true"] {
    background: rgba(182, 182, 182, 0.5);
    border-color: rgb(194, 194, 194);
}
.selectable-card[data-selected="true"][data-tier="1"][data-disabled="false"] {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: #FFFFFF;
}

.selectable-card[data-selected="false"][data-tier="1"][data-disabled="false"][data-selectable="true"]:hover {
    background: var(--color-primary-light);
    color: #FFFFFF;
}


.selectable-card[data-tier="2"] {
    margin-top: 3px;
    margin-bottom: 3px;
    user-select: none;
}
.selectable-card[data-tier="2"][data-disabled="false"] {
    background: rgb(224, 224, 224);
    border-color: rgb(117, 117, 117);
}
.selectable-card[data-tier="2"][data-disabled="true"] {
    background: rgb(240, 240, 240);
    border-color: rgb(150, 150, 150);
}
.selectable-card[data-tier="2"][data-highlighted][data-disabled="false"] {
    background: rgb(193, 193, 193);
    border-color: rgb(104, 104, 104);
}
.selectable-card[data-tier="2"][data-highlighted][data-disabled="true"] {
    background: rgb(223, 223, 223);
    border-color: rgb(150, 150, 150);
}
.selectable-card[data-selected="true"][data-tier="2"][data-small="false"][data-disabled="false"] {
    background: rgb(90, 90, 90);
    border-color: rgb(66, 66, 66);
    /* color: #FFFF; */
}
.selectable-card[data-selected="false"][data-tier="2"][data-small="false"][data-disabled="false"][data-selectable="true"]:hover {
    background: rgb(167, 167, 167);
    /* color: #FFFF; */
}
.selectable-card[data-selected="true"][data-tier="2"][data-small="true"][data-disabled="false"] {
    background: rgb(187, 187, 187);
    border-color: rgb(124, 124, 124);
}
.selectable-card[data-selected="false"][data-tier="2"][data-small="true"][data-disabled="false"][data-selectable="true"]:hover {
    background: rgb(196, 196, 196);
    border-color: rgb(136, 136, 136);
    color: rgb(39, 39, 39);
}

.selectable-card[data-selected="false"][data-tier="1"][data-disabled="false"][data-selectable="true"]:hover .icon,
.selectable-card[data-selected="true"][data-tier="1"][data-disabled="false"] .icon {
/* .selectable-card[data-selected="true"][data-tier="2"][data-small="false"][data-disabled="false"] .icon, */
/* .selectable-card[data-selected="false"][data-tier="2"][data-small="false"][data-disabled="false"][data-selectable="true"]:hover .icon { */
    color: #FFFF;
}


.selectable-card .react-date-picker__wrapper,
.selectable-card .react-date-picker__calendar-button {
    width: 100%;
}
.selectable-card .react-date-picker__inputGroup,
.selectable-card .react-date-picker__clear-button {
    display: none
}
.selectable-card .react-date-picker__calendar-button > svg {
    visibility: hidden;
}
.selectable-card .react-calendar__tile--active {
    background: #fff;
    color: #000;
}
.selectable-card .react-calendar__tile {
    padding: 1.2rem 0.5em !important;
    font-size: 0.9rem;
}
.selectable-card .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0.6em;
}
.selectable-card .react-date-picker__calendar {
    width: 300px;
}

/* #analyticsTableHeadMoreInfo {
    width: 46px !important;
} */

.project-analytics-container th.center,
.project-analytics-container td  {
    text-align: center
}
.project-analytics-container .table td {
    /* font-size: 0.5rem; */
    font-weight: 300;
}
.project-analytics-container .table th {
    font-weight: 400;
    /* font-size: 1rem; */
}

@media (max-width: 992px) {
    .project-analytics-container .table td,
    .project-analytics-container .table th {
        padding: .5rem .4rem;
    }
}
.navbar {
    background-color: var(--color-primary-dark-border);
    padding: 8px 12px;
    color: white;
}



@media (max-width: 992px) {
    .navbar {
        background-color: var(--color-primary-dark-border);
        padding: 8px;
        color: white;
    }
    .navbar .navbar-buttons {
        margin-top: 12px;
        float: right;
    }

    .navbar .navbar-brand {
        font-size: 1.3rem !important;
    }
}

.navbar > .navbar-brand {
    display: inline-block;
    margin-left: 24px;
    font-size: 1.3em;
}

.navbar button {
    font-size: 0.95rem;
    font-weight: 400;
}
.new-marker-row {
  display: flex;
  align-items: center;
  padding: 6px 6px;
  min-width: 160px;
  border-radius: 2px;

  p {
    margin: 0;
    height: 17px;
  }

  &:hover {
    cursor: pointer;
    font-weight: 600;
    background-color: #e1e1e1;
  }

  .marker {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 12px;
    border: solid 1px #e1e1e1;
  }
}
